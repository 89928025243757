import { Couche } from '@/interfaces/couches';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CouchesService } from '@services/couches.service';
import { ThematiquesService } from '@services/thematiques.service';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-couches',
  templateUrl: './couches.component.html',
  styleUrls: ['./couches.component.scss']
})
export class CouchesComponent {
  couches: Couche[];
  environment;
  dtOptions: any = {};

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();

  constructor(public thematiquesService: ThematiquesService, public couchesService:CouchesService, private toastr: ToastrService, private router: Router) {}

  ngOnInit(): void {
    this.environment = environment;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      language: {
        processing: 'Traitement...',
        search: 'Rechercher:',
        lengthMenu: 'Voir _MENU_ &eacute;l&eacute;ments',
        info: 'Affichage de _START_ à _END_ sur _TOTAL_ éléments',
        infoEmpty: "Il n'y a pas d'éléments.",

        emptyTable: 'Aucune donnée disponible dans le tableau',
        paginate: {
          first: 'Premier',
          previous: 'Précédent',
          next: 'Suivant',
          last: 'Dernier'
        }
      },
      dom: 'Bfrtip',
      buttons: ['copy', 'print', 'excel']
    };

    this.couchesService.loadCouches().then(
      response => {
        this.couches = this.couchesService.getCouches().data.couches;

        this.dtTrigger.next(this.dtOptions);
      },
      error => {
        this.toastr.error('Echec lors du chargement des couches');
      }
    );
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();

      this.couchesService.loadCouches().then(
        response => {
          this.couches = this.couchesService.getCouches().data.couches;

          this.dtTrigger.next(this.dtOptions);
        },
        error => {
          this.toastr.error('Echec lors du chargement des couches');
        }
      );
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  createCouche() {
    this.router.navigate(['/couches/create']);
  }

  viewCouche(couche: Couche) {
    this.router.navigate(['/couches/view'], {
      state: {
       couche: JSON.stringify(couche),
        site: 'geosm.org'
      }
    });
  }

  editCouche(couche: Couche) {
    this.router.navigate(['/couches/edit'], {
      state: {
        couche: JSON.stringify(couche),
        site: 'geosm.org'
      }
    });
  }

  deleteCouche(id: number) {
    this.couchesService.deleteCouche(id).then(
      response => {
        this.toastr.success('Suppression reussie');
        this.rerender();
      },
      error => {
        this.toastr.error('Erreur lors de la suppression');
      }
    );
  }

  addMeta(id:number) {
this.couchesService.addMeta(id).then(
  response => {
    this.toastr.success('Ajout Reussi');
    this.rerender();
  },
  error => {
    this.toastr.error('Erreur');
  }
);
  }
}
