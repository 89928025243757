import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ThematiquesService } from '@services/thematiques.service';
import { ToastrService } from 'ngx-toastr';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-create.thematique',
  templateUrl: './create.thematique.component.html',
  styleUrls: ['./create.thematique.component.scss']
})
export class CreateThematiqueComponent implements OnInit {
  ngOnInit(): void {
    this.thematiqueForm = new UntypedFormGroup({
      nom: new UntypedFormControl(null, Validators.required),
      nom_en: new UntypedFormControl(null, Validators.required),
      color: new UntypedFormControl(null, Validators.required)
    });
  }

  constructor(public thematiquesService: ThematiquesService, private toastr: ToastrService, private router: Router) {}
  public thematiqueForm: UntypedFormGroup;
  color: string = '#6b3737';
  selectColor;
  public isThematiqueLoading = false;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  preview = 'assets/img/defaut.png';

  imageInfos?: Observable<any>;

  selectFile(event: any): void {
    this.message = '';
    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e.target.result);
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  async addThematique() {
    const file: File | null = this.selectedFiles.item(0);

    if (this.thematiqueForm.valid) {
      this.isThematiqueLoading = true;
      const formData = new FormData();
      formData.append('image_src', file, file.name);
      formData.append('nom', this.thematiqueForm.value.nom);
      formData.append('nom_en', this.thematiqueForm.value.nom_en);
      formData.append('color', this.selectColor);
      await this.thematiquesService.addThematique(formData).then((response: { error: boolean; msg?: string; results?: any }) => {
        if (!response.results.success) {
          this.toastr.error('Echec de Création');
        } else {
          this.toastr.success('Création de la thématique réussie');
          this.router.navigate(['/thematiques']);
        }
      });
      this.isThematiqueLoading = false;
    } else {
      this.toastr.error('Formulaire non valide');
    }
  }

  updateColor(event: any) {
    this.selectColor = event.value;
    console.log(this.selectColor);
  }
}
