import { SousThematique } from '@/interfaces/sousthematiques';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-view.sousthematique',
    templateUrl: './view.sousthematique.component.html',
    styleUrls: ['./view.sousthematique.component.scss']
})
export class ViewSousthematiqueComponent implements OnInit {
    sousthematique: SousThematique;
    environment;

    data: {sousthematique: SousThematique; site: string} = {
        sousthematique: undefined,
        site: ''
    };
    routeState: any;

    constructor(public router: Router) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            this.routeState = this.router.getCurrentNavigation()?.extras.state;
            if (this.routeState) {
                this.data.sousthematique = this.routeState.sousthematique
                    ? JSON.parse(this.routeState.sousthematique)
                    : '';
                this.sousthematique = this.data.sousthematique;
                this.data.site = this.routeState.site
                    ? this.routeState.site
                    : '';
            }
        }
    }
    ngOnInit(): void {
        this.environment = environment;
    }
}
