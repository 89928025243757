import { SousThematique } from '@/interfaces/sousthematiques';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SousthematiquesService } from '@services/sousthematiques.service';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-sousthematiques',
    templateUrl: './sousthematiques.component.html',
    styleUrls: ['./sousthematiques.component.scss']
})
export class SousthematiquesComponent implements OnInit {
    sousthematiques: SousThematique[];
    environment;
    dtOptions: any = {};

    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();

    constructor(
        public sousthematiquesService: SousthematiquesService,
        private toastr: ToastrService,
        private router: Router
    ) {}
    ngOnInit(): void {
        this.environment = environment;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 5,
            processing: true,
            language: {
                processing: 'Traitement...',
                search: 'Rechercher:',
                lengthMenu: 'Voir _MENU_ &eacute;l&eacute;ments',
                info: 'Affichage de _START_ à _END_ sur _TOTAL_ éléments',
                infoEmpty: "Il n'y a pas d'éléments.",

                emptyTable: 'Aucune donnée disponible dans le tableau',
                paginate: {
                    first: 'Premier',
                    previous: 'Précédent',
                    next: 'Suivant',
                    last: 'Dernier'
                }
            },
            dom: 'Bfrtip',
            buttons: ['copy', 'print', 'excel']
        };

        this.sousthematiquesService.loadSousThematiques().then(
            (response) => {
                this.sousthematiques =
                    this.sousthematiquesService.getSousThematiques().data.sous_thematiques;

                this.dtTrigger.next(this.dtOptions);
            },
            (error) => {
                this.toastr.error('Echec lors du chargement des thématiques');
            }
        );
    }

    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();

            this.sousthematiquesService.loadSousThematiques().then(
                (response) => {
                    this.sousthematiques =
                        this.sousthematiquesService.getSousThematiques().data.sous_thematiques;

                    this.dtTrigger.next(this.dtOptions);
                },
                (error) => {
                    this.toastr.error(
                        'Echec lors du chargement des thématiques'
                    );
                }
            );
        });
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    createSousThematique() {
        this.router.navigate(['/sousthematiques/create']);
    }

    viewSousThematique(sousthematique: SousThematique) {
        this.router.navigate(['/sousthematiques/view'], {
            state: {
                sousthematique: JSON.stringify(sousthematique),
                site: 'geosm.org'
            }
        });
    }

    editSousThematique(sousthematique: SousThematique) {
        this.router.navigate(['/sousthematiques/edit'], {
            state: {
                sousthematique: JSON.stringify(sousthematique),
                site: 'geosm.org'
            }
        });
    }

    deleteSousThematique(id: number) {
        this.sousthematiquesService.deleteSousThematique(id).then(
            (response) => {
                this.toastr.success('Suppression reussie');
                this.rerender();
            },
            (error) => {
                this.toastr.error('Erreur lors de la suppression');
            }
        );
    }
}
