<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Sous Thematiques</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Acceuil</a></li>
                    <li class="breadcrumb-item active">Sous-Thematiques</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
   <div class="main-card mb-3 card">
    <div class="card-header  align-items-end">
        <p class="card-header-title font-size-lg text-capitalize fw-normal">Liste des Sous-Thématiques</p>
        
            <button class="btn-shadow btn btn-success" (click)="createSousThematique()">Nouvelle Sous-Thématique</button>
        
    </div>
    <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="align-middle text-truncate mb-0 table table-borderless table-hover table table-striped">
            <thead>
                <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Logo</th>
                    <th class="text-center">Nom Thématique</th>
                    <th class="text-center">Nom</th>
                    <th class="text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let sousthematique of sousthematiques">
                    <td class="text-center text-muted" style="width: 80px;">#{{ sousthematique.id }}</td>
                     <td class="text-center" style="width: 80px;">
                        <img width="40" class="rounded-circle" src="{{ environment.apiUrl+sousthematique.image_src }}"
                            alt="">
                    </td>
                    <td class="text-center"><a [routerLink]="">{{ sousthematique.thematique[0].nom }}</a></td>
                   
                    <td class="text-center"><a [routerLink]="">{{ sousthematique.nom }}</a></td>
                   
                    <td class="text-center">
                        <div role="group" class="btn-group-sm btn-group">
                            <button class="btn-shadow btn btn-success" (click)="viewSousThematique(sousthematique)">Voir </button>
                            <button  class="btn-shadow btn btn-primary"
                                (click)="editSousThematique(sousthematique)">Editer</button>
                            <button  class="btn-shadow btn btn-danger"
                               (click)="deleteSousThematique(sousthematique.id)" >Supprimer</button>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>

</div>
</section>
<!-- /.content -->