<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Couches</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Acceuil</a></li>
                    <li class="breadcrumb-item active">Couches</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
   <div class="main-card mb-3 card">
    <div class="card-header  align-items-end">
        <p class="card-header-title font-size-lg text-capitalize fw-normal">Liste des Couches</p>
        
            <button class="btn-shadow btn btn-success" (click)="createCouche()">Nouvelle Couche</button>
        
    </div>
    <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="align-middle text-truncate mb-0 table table-borderless table-hover table table-striped">
            <thead>
                <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Logo</th>
                    <th class="text-center">Sous-Thématique</th>
                    <th class="text-center">Nom</th>
                    <th class="text-center">Geometrie</th>
                    <th class="text-center">Couleur</th>
                    <th class="text-center">Service Carto</th>
                    <th class="text-center">Url QGIS</th>
                    <th class="text-center">Nombre Features</th>
                    <th class="text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let couche of couches">
                    <td class="text-center text-muted" style="width: 80px;">#{{ couche.id }}</td>
                    <td class="text-center" style="width: 80px;">
                        <img width="40" class="rounded-circle" src="{{ environment.apiUrl+couche.logo }}"
                            alt="">
                    </td>
                    <td class="text-center"><a [routerLink]="">{{ couche.sousThematique[0].nom }}</a></td>
                    <td class="text-center"><a [routerLink]="">{{ couche.nom }}</a></td>
                    <td class="text-center"><a [routerLink]="">{{ couche.geometry }}</a></td>
                    <td class="text-center"><a [routerLink]="">{{ couche.remplir_color }}</a></td>
                    <td class="text-center"><a [routerLink]="">{{ couche.service_carto }}</a></td>
                    <td class="text-center"><a [routerLink]="">{{ couche.qgis_url }}</a></td>
                    <td class="text-center"><a [routerLink]="">{{ couche.number_features }}</a></td>


                   
                    <td class="text-center">
                        <div role="group" class="btn-group-sm btn-group">
                            <button class="btn-shadow btn btn-success" (click)="viewCouche(couche)">Voir </button>
                             
                           <button *ngIf="!couche.metadatas"  class="btn-shadow btn btn-primary"
                               (click)="addMeta(couche.id)" >Metadatas</button>
                            <button  class="btn-shadow btn btn-danger"
                               (click)="deleteCouche(couche.id)" >Supprimer</button>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>

</div>
</section>
<!-- /.content -->