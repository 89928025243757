import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, catchError, forkJoin, from, of } from 'rxjs';
import { Thematique, ThematiqueInterface } from '@/interfaces/thematiques';

@Injectable({
  providedIn: 'root'
})
export class ThematiquesService {
  constructor(public apiService: ApiService) {}

  public thematiques: BehaviorSubject<ThematiqueInterface> = new BehaviorSubject<ThematiqueInterface>({} as ThematiqueInterface);

  loadThematiques(): Promise<{ error: boolean; msg?: string }> {
    return new Promise((resolve, reject) => {
      forkJoin([from(this.apiService.getRequest('/api/thematiques'))])
        .pipe(catchError(err => of({ error: true, msg: err })))
        .subscribe(results => {
          this.thematiques.next(results[0]);

          resolve({
            msg: 'Success',
            error: false
          });
        });
    });
  }

  addThematique(data: FormData): Promise<{ error: boolean; msg?: string; results?: any }> {
    return new Promise((resolve, reject) => {
      forkJoin([from(this.apiService.post_requete_file('/api/thematiques', data))])
        .pipe(catchError(err => of({ error: true, msg: err })))
        .subscribe(results => {
          resolve({
            results: results[0],
            msg: 'Success',
            error: false
          });
        });
    });
  }

  updateThematique(id: number, data: FormData): Promise<{ error: boolean; msg?: string; results?: any }> {
    return new Promise((resolve, reject) => {
      forkJoin([from(this.apiService.post_requete_file('/api/thematiques/' + id, data))])
        .pipe(catchError(err => of({ error: true, msg: err })))
        .subscribe(results => {
          resolve({
            results: results[0],
            msg: 'Success',
            error: false
          });
        });
    });
  }

  deleteThematique(id: number): Promise<{ error: boolean; msg?: string; results?: any }> {
    return new Promise((resolve, reject) => {
      forkJoin([
        from(
          this.apiService.post_requete('/api/thematiques/' + id, {
            _method: 'delete'
          })
        )
      ])
        .pipe(catchError(err => of({ error: true, msg: err })))
        .subscribe(results => {
          resolve({
            results: results[0],
            msg: 'Success',
            error: false
          });
        });
    });
  }

  getThematiques(): ThematiqueInterface {
    return this.thematiques.getValue();
  }
}
