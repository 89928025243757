import { Thematique } from '@/interfaces/thematiques';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ThematiquesService } from '@services/thematiques.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-edit.thematique',
    templateUrl: './edit.thematique.component.html',
    styleUrls: ['./edit.thematique.component.scss']
})
export class EditThematiqueComponent implements OnInit {
    thematique: Thematique;
    environment;
    selectColor;
    public isThematiqueUpdateLoading = false;
    public thematiqueUpdateForm: UntypedFormGroup;

    selectedFiles?: FileList;
    currentFile?: File;
    progress = 0;
    message = '';
    preview = '';

    data: {thematique: Thematique; site: string} = {
        thematique: undefined,
        site: ''
    };
    routeState: any;

    constructor(
        public router: Router,
        public thematiquesService: ThematiquesService,
        private toastr: ToastrService
    ) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            this.routeState = this.router.getCurrentNavigation()?.extras.state;
            if (this.routeState) {
                this.data.thematique = this.routeState.thematique
                    ? JSON.parse(this.routeState.thematique)
                    : '';
                this.thematique = this.data.thematique;
                this.data.site = this.routeState.site
                    ? this.routeState.site
                    : '';
            }
        }
    }

    ngOnInit(): void {
      this.environment = environment;
      this.preview = environment.apiUrl + this.thematique.image_src;
        this.thematiqueUpdateForm = new UntypedFormGroup({
            nom: new UntypedFormControl(
                this.thematique.nom,
                Validators.required
            ),
            nom_en: new UntypedFormControl(
                this.thematique.nom_en,
                Validators.required
            )
        });
    }

    updateColor(event: any) {
        this.selectColor = event.value;
    }

    selectFile(event: any): void {
        this.message = '';
        this.preview = '';
        this.progress = 0;
        this.selectedFiles = event.target.files;

        if (this.selectedFiles) {
            const file: File | null = this.selectedFiles.item(0);

            if (file) {
                this.preview = '';
                this.currentFile = file;

                const reader = new FileReader();

                reader.onload = (e: any) => {
                    console.log(e.target.result);
                    this.preview = e.target.result;
                };

                reader.readAsDataURL(this.currentFile);
            }
        }
    }

  async updateThematique() {
    let file: File | null = null;
     const formData = new FormData();
    if (this.selectedFiles) {
       
      file = this.selectedFiles.item(0); 
      formData.append('image_src', file, file.name);
    }
    
    if (this.selectColor) {
      formData.append('color', this.selectColor);
    }

        if (this.thematiqueUpdateForm.valid) {
            this.isThematiqueUpdateLoading = true;
           
            
            formData.append('nom', this.thematiqueUpdateForm.value.nom);
            formData.append('nom_en', this.thematiqueUpdateForm.value.nom_en);
          
          formData.append('_method','put')

            await this.thematiquesService
                .updateThematique(this.thematique.id, formData)
                .then((response: { error: boolean; msg?: string; results?:any}) => {
                    if (!response.results.success) {
                      this.toastr.error('Echec de Mise à jour');
                    } else {
                      this.toastr.success('Mise à jour de la thématique réussie');
                      this.router.navigate(['/thematiques']);
                    }
                });
            this.isThematiqueUpdateLoading = false;
        } else {
            this.toastr.error('Formulaire non valide');
        }
    }
}
